/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 80%;
  margin: 0 auto;
  line-height: 1.8;
  text-align: center;
  border-style: hidden; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

.clearfix {
  display: inline-block; }

* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }

h1 {
  font-size: 24px;
  line-height: 1.2; }

h2 {
  font-size: 18px;
  line-height: 1.5; }

h3 {
  font-size: 14px;
  line-height: 1.5; }

p {
  font-size: 16px;
  line-height: 1.8; }

small {
  font-size: 14px;
  line-height: 1.5; }

.m-xs {
  margin: 4px; }

.m-sm {
  margin: 8px; }

.m {
  margin: 16px; }

.m-md {
  margin: 24px; }

.m-lg {
  margin: 32px; }

.m-n {
  margin: 0; }

.m-l-none {
  margin-left: 0; }

.m-l-xs {
  margin-left: 4px; }

.m-l-sm {
  margin-left: 8px; }

.m-l {
  margin-left: 16px; }

.m-l-md {
  margin-left: 24px; }

.m-l-lg {
  margin-left: 32px; }

.m-l-xl {
  margin-left: 40px; }

.m-l-n-xxs {
  margin-left: -1px; }

.m-l-n-xs {
  margin-left: -4px; }

.m-l-n-sm {
  margin-left: -8px; }

.m-l-n {
  margin-left: -16px; }

.m-l-n-md {
  margin-left: -24px; }

.m-l-n-lg {
  margin-left: -32px; }

.m-l-n-xl {
  margin-left: -40px; }

.m-t-none {
  margin-top: 0; }

.m-t-xxs {
  margin-top: 1px; }

.m-t-xs {
  margin-top: 4px; }

.m-t-sm {
  margin-top: 8px; }

.m-t {
  margin-top: 16px; }

.m-t-md {
  margin-top: 24px; }

.m-t-lg {
  margin-top: 32px; }

.m-t-xl {
  margin-top: 40px; }

.m-t-n-xxs {
  margin-top: -1px; }

.m-t-n-xs {
  margin-top: -4px; }

.m-t-n-sm {
  margin-top: -8px; }

.m-t-n {
  margin-top: -16px; }

.m-t-n-md {
  margin-top: -24px; }

.m-t-n-lg {
  margin-top: -32px; }

.m-t-n-xl {
  margin-top: -40px; }

.m-r-none {
  margin-right: 0; }

.m-r-xs {
  margin-right: 4px; }

.m-r-sm {
  margin-right: 8px; }

.m-r {
  margin-right: 16px; }

.m-r-md {
  margin-right: 24px; }

.m-r-lg {
  margin-right: 32px; }

.m-r-xl {
  margin-right: 40px; }

.m-r-n-xxs {
  margin-right: -1px; }

.m-r-n-xs {
  margin-right: -4px; }

.m-r-n-sm {
  margin-right: -8px; }

.m-r-n {
  margin-right: -16px; }

.m-r-n-md {
  margin-right: -24px; }

.m-r-n-lg {
  margin-right: -32px; }

.m-r-n-xl {
  margin-right: -40px; }

.m-b-none {
  margin-bottom: 0; }

.m-b-xs {
  margin-bottom: 4px; }

.m-b-sm {
  margin-bottom: 8px; }

.m-b {
  margin-bottom: 16px; }

.m-b-md {
  margin-bottom: 24px; }

.m-b-lg {
  margin-bottom: 32px; }

.m-b-xl {
  margin-bottom: 40px; }

.m-b-n-xxs {
  margin-bottom: -1px; }

.m-b-n-xs {
  margin-bottom: -4px; }

.m-b-n-sm {
  margin-bottom: -8px; }

.m-b-n {
  margin-bottom: -16px; }

.m-b-n-md {
  margin-bottom: -24px; }

.m-b-n-lg {
  margin-bottom: -32px; }

.m-b-n-xl {
  margin-bottom: -40px; }

.m-x-xs {
  margin: 0 4px; }

.m-x-sm {
  margin: 0 8px; }

.m-x-md {
  margin: 0 16px; }

.m-x {
  margin: 0 24px; }

.m-x-lg {
  margin: 0 32px; }

.m-x-xl {
  margin: 0 40px; }

.m-y-xs {
  margin: 4px 0; }

.m-y-sm {
  margin: 8px 0; }

.m-y-md {
  margin: 16px 0; }

.m-y {
  margin: 24px 0; }

.m-y-lg {
  margin: 32px 0; }

.m-y-xl {
  margin: 40px 0; }

.p-xs {
  padding: 4px; }

.p-sm {
  padding: 8px; }

.p {
  padding: 16px; }

.p-md {
  padding: 24px; }

.p-lg {
  padding: 32px; }

.p-n {
  padding: 0; }

.p-l-none {
  padding-left: 0; }

.p-l-xs {
  padding-left: 4px; }

.p-l-sm {
  padding-left: 8px; }

.p-l {
  padding-left: 16px; }

.p-l-md {
  padding-left: 24px; }

.p-l-lg {
  padding-left: 32px; }

.p-l-xl {
  padding-left: 40px; }

.p-t-none {
  padding-top: 0; }

.p-t-xxs {
  padding-top: 1px; }

.p-t-xs {
  padding-top: 4px; }

.p-t-sm {
  padding-top: 8px; }

.p-t {
  padding-top: 16px; }

.p-t-md {
  padding-top: 24px; }

.p-t-lg {
  padding-top: 32px; }

.p-t-xl {
  padding-top: 40px; }

.p-r-none {
  padding-right: 0; }

.p-r-xs {
  padding-right: 4px; }

.p-r-sm {
  padding-right: 8px; }

.p-r {
  padding-right: 16px; }

.p-r-md {
  padding-right: 24px; }

.p-r-lg {
  padding-right: 32px; }

.p-r-xl {
  padding-right: 40px; }

.p-b-none {
  padding-bottom: 0; }

.p-b-xs {
  padding-bottom: 4px; }

.p-b-sm {
  padding-bottom: 8px; }

.p-b {
  padding-bottom: 16px; }

.p-b-md {
  padding-bottom: 24px; }

.p-b-lg {
  padding-bottom: 32px; }

.p-b-xl {
  padding-bottom: 40px; }

.p-x-xs {
  padding: 0 4px; }

.p-x-sm {
  padding: 0 8px; }

.p-x-md {
  padding: 0 16px; }

.p-x {
  padding: 0 24px; }

.p-x-lg {
  padding: 0 32px; }

.p-x-xl {
  padding: 0 40px; }

.p-y-xs {
  padding: 4px 0; }

.p-y-sm {
  padding: 8px 0; }

.p-y-md {
  padding: 16px 0; }

.p-y {
  padding: 24px 0; }

.p-y-lg {
  padding: 32px 0; }

.p-y-xl {
  padding: 40px 0; }

.hide {
  display: none; }

.btn {
  width: 163px;
  height: 30px;
  line-height: 30px;
  text-align: center; }

.btn-primary {
  background-color: #FA6D01;
  color: white; }

a[href^="mailto"] {
  text-decoration: none;
  color: #0C7CD2; }

/* START CUSTOM */
body {
  font-family: "MuseoSans-300", arial, sans-serif; }

.thick {
  font-family: "MuseoSans-700", "arial bold"; }

/* HEADER */
#blocksHeader {
  position: relative;
  border-bottom: 1px solid #dadada; }

.main-header {
  height: 17px;
  background-color: #1BA0E2; }

.sub-header {
  height: 54px;
  width: 900px;
  margin: 0 auto;
  display: block; }
  .sub-header img {
    width: 54px;
    height: auto;
    display: inline-block;
    vertical-align: middle; }
  .sub-header h1 {
    display: inline-block;
    color: #0D7FCC; }
  .sub-header a {
    font-size: 20px;
    color: #434343;
    margin-right: 35px;
    text-decoration: none; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

/* END HEADER */
/* CONTENT */
#blocksContent {
  width: 900px;
  margin: 0 auto; }

.homepage__wide-products {
  text-align: center; }
  .homepage__wide-products a {
    margin-right: 72px; }
    .homepage__wide-products a img {
      width: 221px;
      height: 147px; }
    .homepage__wide-products a:nth-last-of-type(1) {
      margin-right: 0 !important; }

.brand__logo img {
  margin: 0 auto;
  display: block;
  width: 221px;
  height: 147px; }

.product__subcontainer {
  cursor: pointer;
  position: relative;
  border-top: 1px solid #979797; }
  .product__subcontainer:nth-last-of-type(1) {
    border-bottom: 1px solid #979797; }
  .product__subcontainer > h2 {
    float: left; }
  .product__subcontainer > i {
    float: right;
    transition-property: transform;
    transition-duration: 0.5s; }
  .product__subcontainer > i.rotateThis {
    transform: rotate(180deg); }

.product__details {
  clear: left; }

.product__subdetails {
  background-color: #F6F6F6;
  clear: left;
  border-bottom: 1px solid #979797; }
  .product__subdetails img {
    display: inline-block;
    width: 74px;
    height: 74px;
    vertical-align: middle;
    margin-right: 16px; }
  .product__subdetails p {
    display: inline-block;
    vertical-align: middle;
    color: #424242;
    width: 500px; }
  .product__subdetails > a {
    text-decoration: none;
    margin: 22px 0;
    float: right; }
  .product__subdetails a.btn-primary:hover {
    background-color: white;
    color: #FA6D01;
    box-shadow: inset 0px 0px 0px 1px; }
  .product__subdetails:nth-last-of-type(1) {
    border-bottom: none !important; }

.subdetails__container {
  float: left; }
  .subdetails__container a {
    text-decoration: none; }

.product__content p {
  padding-top: 16px; }

.product__content b {
  font-family: "MuseoSans-500", "Arial Bold"; }

.product__specification {
  margin-top: 32px;
  margin-bottom: 32px; }
  .product__specification ol {
    margin-left: 18px; }
  .product__specification li {
    line-height: 1.8;
    list-style-type: disc; }
  .product__specification tr {
    border-bottom: 1px solid #979797; }
  .product__specification th, .product__specification td {
    border-right: 1px solid #979797; }
  .product__specification th {
    font-family: MuseoSans-500, arial-bold, sans-serif;
    font-weight: 500; }
  .product__specification tbody tr:nth-child(odd) {
    background-color: #F6F6F6; }
  .product__specification tbody tr:nth-last-of-type(1) {
    border-bottom: none !important; }
  .product__specification thead th:nth-last-of-type(1), .product__specification tbody td:nth-last-of-type(1) {
    border-right: none !important; }
  .product__specification tbody td {
    vertical-align: middle; }

.product__image {
  text-align: center; }
  .product__image img {
    margin: 0 auto;
    display: block; }

/* END CONTENT */
/* FOOTER */
#blocksFooter {
  width: 900px;
  background-color: #F4FBFE;
  margin: 40px auto 0; }

.block-footer {
  display: inline-block;
  margin-right: 56px;
  vertical-align: top; }
  .block-footer a {
    text-decoration: none;
    color: #0C7CD2;
    line-height: 1.8; }
  .block-footer:nth-last-of-type(1) {
    margin-right: 0 !important; }

/* END FOOTER */
head {
  margin-top: 100px; }
